<template>
  <a-modal
    :visible="show"
    destroy-on-close
    width="600px"
    :mask-closable="false"
    @cancel="cancel"
    :title="title"
  >
    <a-form class="myform" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
      <a-form-item label="Full Name">
        <a-input v-model:value="record.fullname" :disabled="readOnly"></a-input>
      </a-form-item>

      <a-form-item label="Username">
        <a-input v-model:value="record.username" disabled></a-input>
      </a-form-item>

      <a-form-item label="Email">
        <a-input v-model:value="record.email" disabled></a-input>
      </a-form-item>

      <a-form-item label="Nomor Telepon">
        <a-input v-model:value="record.phone" disabled></a-input>
      </a-form-item>

      <a-form-item label="Role">
        <filter-role
          :mode="null"
          show-search
          v-model:value="record.role_id"
          :disabled="readOnly"
        ></filter-role>
      </a-form-item>

      <a-form-item label="Status">
        <a-radio-group :disabled="readOnly" v-model:value="record.active">
          <a-radio :value="true">Aktif</a-radio>
          <a-radio :value="false">Non Aktif</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>

    <template #footer>
      <a-button
        key="submit"
        @click="submit(record)"
        v-if="!readOnly"
        type="primary"
        :loading="loading"
        >Simpan</a-button
      >
      <a-button key="cancel" @click="cancel">Cancel</a-button>
    </template>
  </a-modal>
</template>

<script>
import { reactive, toRefs, ref } from 'vue'

import FilterRole from '@/components/filter/FilterRole'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
export default {
  components: {
    FilterRole,
  },
  props: {
    show: [Boolean],
    state: {
      type: Object,
      default: () => {
        return {
          title: null,
          record: null,
          readOnly: null,
        }
      },
    },
  },
  emits: ['update:show', 'refresh'],
  setup(props, { emit }) {
    const newState = reactive(JSON.parse(JSON.stringify(props.state)))
    const loading = ref(false)
    const submit = record => {
      Modal.confirm({
        title: 'Konfirmasi Ubah Role',
        content: 'Apakah anda ingin melanjutkan Ubah Role ?',
        onOk() {
          loading.value = true
          apiClient
            .post('/api/users/' + record.id + '?_method=PUT', {
              ...record,
              update_role: true,
            })
            .then(({ data }) => {
              emit('update:show', false)
              emit('refresh')
            })
            .catch(e => {
              message.error('Gagal mengupdate!')
              loading.value = false
            })
            .finally(() => (loading.value = false))
        },
        onCancel() {},
      })
    }
    const cancel = e => {
      emit('update:show', false)
    }

    return { visible: true, submit, cancel, ...toRefs(newState), loading }
  },
}
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
